import { wrapView } from '../smart_bps/lib/utils'
import { reportStateToProps } from '../smart_bps/report/index_view'
import { ReportBaseView } from '../smart_bps/report/report_view'


class ReportView extends ReportBaseView {
  name = 'employee_work'

  constructor(props) {
    super(props)
  }

  renderContent() {
    const thead = (
      <thead>
        <tr>
          <th colspan="3" className="text-center">Сотрудник</th>
          <th colspan="6" className="text-center">Redmine</th>
          <th colspan="3" className="text-center">Git</th>
        </tr>
        <tr>
          <th>Фамилия</th>
          <th>Имя</th>
          <th>Стаж</th>

          <th>Комментарии</th>
          <th>Часов общих</th>
          <th>Часов внутренних</th>
          <th>Эффективных часов</th>
          <th>Часов общих по периоду</th>
          <th>Рабочих дней по периоду</th>

          <th>Строк</th>
          <th>Коммитов</th>
          <th>Слияний</th>
        </tr>
      </thead>
    )

    return (
      <div>

        <div className="card mb-3" key="sber_1">
          <div className="card-body p-3">
            <h5 className="card-title">Разработчики Сбербанк - Цифровое УУ</h5>
            <div className="card-text">
              {this.renderTable({ data_name: "sber_1", thead: thead })}
            </div>
          </div>
        </div>

        <div className="card mb-3" key="sber_2">
          <div className="card-body p-3">
            <h5 className="card-title">Разработчики Сбербанк - Прочее</h5>
            <div className="card-text">
              {this.renderTable({ data_name: "sber_2", thead: thead })}
            </div>
          </div>
        </div>

        <div className="card mb-3" key="sber_crm">
          <div className="card-body p-3">
            <h5 className="card-title">Разработчики Сбербанк - CRM</h5>
            <div className="card-text">
              {this.renderTable({ data_name: "sber_crm", thead: thead })}
            </div>
          </div>
        </div>

        <div className="card mb-3" key="vetro">
          <div className="card-body p-3">
            <h5 className="card-title">Разработчики Ветро</h5>
            <div className="card-text">
              {this.renderTable({ data_name: "vetro", thead: thead })}
            </div>
          </div>
        </div>

        <div className="card mb-3" key="analyst">
          <div className="card-body p-3">
            <h5 className="card-title">Аналитики СтратоСфера</h5>
            <div className="card-text">
              {this.renderTable({ data_name: "analyst", thead: thead })}
            </div>
          </div>
        </div>

        <div className="card mb-3" key="other">
          <div className="card-body p-3">
            <h5 className="card-title">Прочие команды</h5>
            <div className="card-text">
              {this.renderTable({ data_name: "other", thead: thead })}
            </div>
          </div>
        </div>

      </div>

    )
  }
}


export default wrapView(ReportView, reportStateToProps)